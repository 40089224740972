<template>
  <div>
    <v-data-table
      id="data-bancos-isola"
      :headers="headerRoles"
      :items="datos"
      :search="search"
      :loading="loadingData"
      :footer-props="{itemsPerPageText: 'Mostrar'}"
    >
      <template v-slot:item.name="{item}">
        <span 
          class="primary--text font-weight-bold" 
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }" 
          v-text="item.name" /> 
      </template>      
      <template v-slot:item.created_at="{ item }">
          <div class="text-right blue-grey--text">
            {{item.created_at | smartDate}}       
          </div>
        </template>
      <template v-slot:item.iconos="{item}">
        <div 
          v-if="$hasPermission('configuracion.roles.editar')"
          class="d-flex flex-row justify-end"
        >
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  plain 
                  icon
                  class="mr-2"
                  :to="{name: 'GestionRol', params: {action: 'actualizar', id: item.id}}"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon                    
                    size="20" 
                    color="blue-grey"                    
                    :left="$vuetify.breakpoint.xsOnly"
                    v-text="'mdi-file-document-edit-outline'"
                  />
                  <span v-if="$vuetify.breakpoint.xsOnly">Editar</span>
                </v-btn>
              </template>
              <span>Editar Rol</span>
          </v-tooltip>           
        </div>
      </template>
      <template #loading>
        <v-skeleton-loader
          type="table-tbody"
        ></v-skeleton-loader>
      </template>
      <template v-slot:no-data>
        <div class="text-center pa-4">
          <span class="blue-grey--text font-italic">No se encontraron Roles Registrados</span>
        </div>
      </template>
    </v-data-table>    
  </div>
</template>
<script>
export default {
  name: 'RolesData',
  props:{
    datos: {
      type: Array,
      default: () => ([])
    },
    search: String,
    loadingData: Boolean,
  },
  data: () => ({    
    headerRoles:[
      { text: '#', value: 'id', class: 'header-data', width: '50' },
      // { text: 'Cod Cliente', value: 'co_cli', class: 'header-data', width: '20', align: ' d-none'},
      // { text: 'Cod Vendedor', value: 'co_ven', class: 'header-data', width: '20', align: ' d-none'},
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        class: 'header-data'
      },     
      { text: 'Fecha Registro', value: 'created_at', class: 'header-data px-0 d-flex justify-end align-center' },
      { text: '', value: 'iconos', class: 'header-data', width: '30'},
    ],
  }),
  methods:{},
}
</script>